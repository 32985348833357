import { render, staticRenderFns } from "./page_not_found.vue?vue&type=template&id=30f78dd0&scoped=true&"
var script = {}
import style0 from "./page_not_found.vue?vue&type=style&index=0&id=30f78dd0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f78dd0",
  null
  
)

export default component.exports